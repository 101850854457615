import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Home from './components/Home'
import Project from './components/Project' 

export default function App() {
  return (<>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} /> 
        <Route exact path="/project/:project" component={Project} />
      </Switch>
    </BrowserRouter>
  </>
  )
}
