import React from "react";

export default function Footer() {

  return <footer>
    <div className='inner'>

      <h3>Get in touch</h3>
      <p>I'd love to hear from you! Please get in touch via <a href='mailto:indiakalff@gmail.com'>email</a>, or connect with me on <a href='https://www.linkedin.com/in/indiakalff/'>LinkedIn</a>.</p>

    </div>
  </footer>

}
