import React, { useState } from "react"
import { HashLink as Link } from 'react-router-hash-link' 
import garms from '../images/garms.gif'
import arrivr from '../images/arrivr.gif'
import spaceinvaders from '../images/spaceinvaders.gif'


export default function Home() {
  const [project, updateProject] = useState('')

  return (<>
    <header className='hero' id='top'>
      <div className='inner'>
        <h1>India Kalff</h1>
        <h2>Full stack developer</h2>


        <ul>
          <li><Link to="/#about">About me <i className='fas fa-chevron-right'></i></Link></li>
          <li><Link to="/#projects">Projects <i className='fas fa-chevron-right'></i></Link></li>
          <li><Link to="/#contact">Contact <i className='fas fa-chevron-right'></i></Link></li>
        </ul>


      </div>
    </header>

    <section id='about'>
      <div className='angletop'><Link to="/#top"><i className='fas fa-chevron-up chevrons'></i></Link></div>
      <div className='colorbg'>
        <div className='inner'>
          <h2>About me</h2>
          <p>I am a London based developer who has recently graduated from a 3-month Software Engineering bootcamp with General Assembly. This covers data science fundamentals and a range of tools, languages and frameworks including HTML5/CSS, JavaScript, React and Python. Prior to studying software engineering, I have worked in a range of roles across the email marketing industry and have a proven track record of working to deliver projects in fast-paced, fast-growing technology companies. </p>

          {
            // <h3>
            //   <i class="fab fa-html5"></i>
            //   <i class="fab fa-react"></i>
            //   <i class="fab fa-js-square"></i>
            //   <i class="fab fa-python"></i>
            //   <i class="fab fa-git"></i>
            //   <i class="fab fa-github"></i>
            // </h3>
          }


        </div>
      </div>
      <div className='anglebottom'><Link to="/#projects"><i className='fas fa-chevron-down chevrons'></i></Link></div>
    </section>
    <section id='projects'>


      <div className='angletop'><Link to="/#about"><i className='fas fa-chevron-up chevrons'></i></Link></div>
      <div className='colorbg'>
        <div className='inner'>

          <h2>Projects</h2>
          <p>A selection of recent projects showcasing my work</p>
          {!project && <div>
            <div class='thumbnails'>
              <div className='thumbnail' onClick={() => updateProject('garms')}><img alt='' src={garms}></img></div>
              <div className='thumbnail' onClick={() => updateProject('arrivr')}><img alt='' src={arrivr}></img></div>
              <div className='thumbnail' onClick={() => updateProject('spaceinvaders')}><img alt='' src={spaceinvaders}></img></div>
            </div>
          </div>
          }
 

          {project === 'garms' &&
            <div className='project'>
              <div className='projectinfo'>
                <div onClick={() => updateProject('')}>
                  <h3>Garms</h3>
                  <p>‘Garms’ is a second-hand clothing app, built for our fourth group project as part of our Software Engineering course. This combines a React front end with a Python/Flask backend. I was responsible for several front end components including adding/editing products, user profile and registration. On the back end I set up the models, controllers and tests for wishlist.</p>
                </div>
                <div className='buttons'>
                  <a className='button view' href='https://ikalff-garms.herokuapp.com'>View online</a>
                  <a className='button github' href='https://github.com/ikalff/project-4'><i className='fab fa-github'></i>Github</a>
                </div>
              </div>
              <div className='projectimage' id='garms' onClick={() => updateProject('')}>

                <button className='button back'><i className='fas fa-times'></i></button>
              </div>
            </div>
          }

          {project === 'arrivr' &&
            <div className='project'>
              <div className='projectinfo'>
                <div onClick={() => updateProject('')}>
                  <h3>Arrivr</h3>
                  <p>
                    ‘Arrivr’ is a simple property booking app, built for our second group project as part of our Software Engineering course. This combines a React front end with a Node.js/MongoDB back end. I worked with my team to set up the models, views and controllers for the API, built several of the front end components & styled the front end using Bulma.</p>
                </div>
                <div className='buttons'>
                  <a className='button view' href='https://ikalff-arrivr.herokuapp.com'>View online</a>
                  <a className='button github' href='https://github.com/ikalff/project_3'><i className='fab fa-github'></i>Github</a>
                </div>
              </div>
              <div className='projectimage' id='arrivr' onClick={() => updateProject('')}>


                <button className='button back'><i className='fas fa-times'></i></button>


              </div>
            </div>
          }

          {project === 'spaceinvaders' &&
            <div className='project'>
              <div className='projectinfo'>
                <div onClick={() => updateProject('')}>
                  <h3>Space Invaders</h3>
                  <p>
                    Space Invaders is a simple arcade game which I designed and built in JavaScript and HTML/CSS as my first project for my Software Engineering course. This was a solo project.</p>
                </div>
                <div className='buttons'>
                  <a className='button view' href='https://ikalff.github.io/SpaceInvaders'>View online</a>
                  <a className='button github' href='https://github.com/ikalff/SpaceInvaders'><i className='fab fa-github'></i>Github</a>
                </div>
              </div>
              <div className='projectimage' id='spaceinvaders' onClick={() => updateProject('')}>
                <button className='button back'><i className='fas fa-times'></i></button>
              </div>
            </div>
          }

        </div>
      </div>
      <div className='anglebottom'><Link to="/#contact"><i className='fas fa-chevron-down chevrons'></i></Link></div>
    </section>
    <footer id='contact'>




      <div className='inner'>

        <Link to="/#top"><i className='fas fa-chevron-up chevrons'></i></Link>

        <h2>Get in touch</h2>
        <p>I'd love to hear from you! </p>
        <p>
          <a className='button' href='mailto:indiakalff@gmail.com'> Email me</a>

          <a className='button' href='https://www.linkedin.com/in/indiakalff/'> Connect on LinkedIn</a>
        </p>
      </div>
    </footer>
  </>
  )
}
