import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from './Footer.js'

export default function Project({ match }) {


  console.log(match.params.project)


  return (<div className='projectcontainer'>

    <nav>
      <div className='inner'><Link to='/'>India Kalff</Link></div></nav>




    <section className='work'>
      <div className='inner'>
        <h2>Work</h2>

      </div>
    </section>


    <Footer></Footer>

  </div>
  )
}
